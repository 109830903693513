import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import AppLayout from "../components/app-layout.js";
import VimeoPlayer from "../components/vimeo-player.js";
import YoutubePlayer from "../components/youtube-player.js";
import Toc from "../components/toc";
import constants from "../constants";
import { useLocalStorage } from "../hooks/localstorage.js";
import { useSiteMetadata } from "../hooks/site-metadata.js";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import "../markdown.css";

export default (props) => {
  const {
    links: { discord },
  } = useSiteMetadata();
  const { episode } = props.data;
  const course = episode.parent;
  const [autoplay, setAutoplay] = useLocalStorage("autoplay", false);

  return (
    <AppLayout course={course} episode={episode}>
      <SEO title={episode.title} description={episode.description} />
      <div className="md:flex mt-40 md:mt-12 md:pt-10 md:mx-1 md:episode-view">
        <div className="md:flex-1 shadow-sm rounded overflow-hidden relative pb-2/3 md:pb-0 w-full h-full">
          {episode.vimeoId ? (
            <VimeoPlayer
              className="absolute inset-0 w-full h-full"
              episode={episode}
              autoplay={autoplay}
            />
          ) : (
            <YoutubePlayer
              className="absolute inset-0 w-full h-full"
              episode={episode}
              autoplay={autoplay}
            />
          )}
        </div>
        <div className="hidden md:block md:ml-1 max-w-sm bg-gray-50">
          <Toc
            active={episode}
            course={course}
            episodes={course.childrenEpisode}
            autoplay={autoplay}
            setAutoplay={(v) => setAutoplay(v)}
          />
        </div>
      </div>
      <div className="px-2 my-2 md:flex-1 md:px-4 lg:px-8 pt-4 py-5 bg-white rounded">
        <div className="sm:flex justify-between">
          <h2 className="font-semibold text-lg">Episode Description</h2>
          <div className="sm:flex items-center text-sm">
            <span className="hidden sm:flex items-center h-5 w-5 mr-1">
              <svg
                className="h-5 w-5 text-teal-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span>
            <span className="font-semibold">Need Help?</span>
            <div className="pl-3 text-sm">
              <OutboundLink
                target="_blank"
                rel="noopener"
                href={discord.url}
                className="group flex items-end font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50 px-1 py-1 rounded"
              >
                <svg
                  className="h-6 w-6 fill-current group-hover:text-teal-500 text-gray-400"
                  viewBox="0 0 245 240"
                >
                  <path d="M104.4 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1.1-6.1-4.5-11.1-10.2-11.1zM140.9 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1s-4.5-11.1-10.2-11.1z" />
                  <path d="M189.5 20h-134C44.2 20 35 29.2 35 40.6v135.2c0 11.4 9.2 20.6 20.5 20.6h113.4l-5.3-18.5 12.8 11.9 12.1 11.2 21.5 19V40.6c0-11.4-9.2-20.6-20.5-20.6zm-38.6 130.6s-3.6-4.3-6.6-8.1c13.1-3.7 18.1-11.9 18.1-11.9-4.1 2.7-8 4.6-11.5 5.9-5 2.1-9.8 3.5-14.5 4.3-9.6 1.8-18.4 1.3-25.9-.1-5.7-1.1-10.6-2.7-14.7-4.3-2.3-.9-4.8-2-7.3-3.4-.3-.2-.6-.3-.9-.5-.2-.1-.3-.2-.4-.3-1.8-1-2.8-1.7-2.8-1.7s4.8 8 17.5 11.8c-3 3.8-6.7 8.3-6.7 8.3-22.1-.7-30.5-15.2-30.5-15.2 0-32.2 14.4-58.3 14.4-58.3 14.4-10.8 28.1-10.5 28.1-10.5l1 1.2c-18 5.2-26.3 13.1-26.3 13.1s2.2-1.2 5.9-2.9c10.7-4.7 19.2-6 22.7-6.3.6-.1 1.1-.2 1.7-.2 6.1-.8 13-1 20.2-.2 9.5 1.1 19.7 3.9 30.1 9.6 0 0-7.9-7.5-24.9-12.7l1.4-1.6s13.7-.3 28.1 10.5c0 0 14.4 26.1 14.4 58.3 0 0-8.5 14.5-30.6 15.2z" />
                </svg>
                <span className="">Come Chat on Discord</span>
              </OutboundLink>

              <OutboundLink
                target="_blank"
                rel="noopener"
                href={`mailto:${constants.EMAIL}`}
                className="group flex items-end font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50 px-1 py-1 rounded"
              >
                <svg
                  className="h-5 w-5 group-hover:text-teal-500 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
                <span className="pl-1">Or Send an Email</span>
              </OutboundLink>
            </div>
          </div>
        </div>
        {episode.description && (
          <div
            className="markdown"
            dangerouslySetInnerHTML={{
              __html: episode.description.childMarkdownRemark.html,
            }}
          />
        )}
      </div>
      <div className="md:hidden bg-gray-50">
        <Toc
          autoScroll={false}
          collapsible={true}
          active={episode}
          course={course}
          episodes={course.childrenEpisode}
          autoplay={autoplay}
          setAutoplay={(v) => setAutoplay(v)}
        />
      </div>
    </AppLayout>
  );
};
export const query = graphql`
  query($id: String!) {
    episode(id: { eq: $id }) {
      slug
      description {
        childMarkdownRemark {
          html
        }
      }
      title
      next
      prev
      url
      youtubeId
      vimeoId
      videoDurationPretty
      parent {
        ... on Course {
          id
          title
          url
          isFree
          description
          childrenEpisode {
            title
            url
            slug
            isFree
            videoDurationPretty
          }
        }
      }
      thumbnailImg {
        childImageSharp {
          fixed(width: 300, height: 300) {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;
