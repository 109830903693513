import { FirebaseContext } from 'gatsby-plugin-firebase'
import { useSession } from '../util/context.js'
import React from 'react'
export const usePaidEpisode = (course, episode) => {
    const [loading, setLoading] = React.useState(true)
    const [episodeData, setEpisodeData] = React.useState(null)

    const fb = React.useContext(FirebaseContext)
    const { user } = useSession()

    React.useEffect(() => {
        if(course.isFree){
            setLoading(false)
            setEpisodeData(episode)
            return
        }

        if (!fb) return
        if (!user) {
            setLoading(false)
            return
        }
        const getEpisode = async () => {
            const userBoughtCourse = (
                await fb
                    .firestore()
                    .collection('users')
                    .doc(user.uid)
                    .collection('courses')
                    .doc(course.id)
                    .get()
            ).exists

            if (!userBoughtCourse) {
                setEpisodeData(null)
                setLoading(false)
                return
            }

            const resp = await fb
                .firestore()
                .collection('courses')
                .doc(course.id)
                .collection('episodes')
                .doc(episode.slug)
                .get()
            setEpisodeData(resp.data())
            setLoading(false)
        }
        getEpisode()
    }, [fb, user, course, episode])

    return [episodeData, loading]
}
