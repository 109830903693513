import React from 'react'
import { Link } from 'gatsby'
import { useLocalStorage } from '../hooks/localstorage.js'
const TocItem = props => {
    const { course, index, episode, isActive, count, autoScroll = true } = props
    const ref = React.useRef()
    //@Todo scroll toc when page changed
    React.useEffect(() => {
        if (!autoScroll) return
        if (isActive) ref.current.scrollIntoViewIfNeeded({block:'nearest'})
    },[])
    return (
        <Link ref={ref} to={episode.url + '#test'}>
            <div
                className={`flex py-2 leading-none transition duration-150 ${
                    isActive ? 'bg-gray-100' : ''
                } hover:bg-gray-200 overflow-hidden`}>
                <div className="">
                    <svg
                        className={`ml-3 mr-2 mt-2 ${isActive ? 'text-teal-400' : 'text-gray-300'}`}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        {isActive ? (
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M9.6 17.4L16.8 12L9.6 6.6V17.4ZM12 0C5.4 0 0 5.4 0 12C0 18.6 5.4 24 12 24C18.6 24 24 18.6 24 12C24 5.4 18.6 0 12 0ZM12 21.6C6.72 21.6 2.4 17.28 2.4 12C2.4 6.72 6.72 2.4 12 2.4C17.28 2.4 21.6 6.72 21.6 12C21.6 17.28 17.28 21.6 12 21.6Z"
                            />
                        ) : (
                            !episode.isFree && !course.isFree && (
                                <path
                                    fillRule="evenodd"
                                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                    clipRule="evenodd"
                                />
                            )
                        )}
                    </svg>
                </div>

                <span className="mr-2 py-1 leading-5 text-gray-500">{index}.</span>
                <div>
                    <h3 className="font-medium py-1 text-sm leading-5 text-gray-700 pr-3">
                        {episode.title}
                    </h3>
                    <span className="font-medium tracking-wide text-xs text-gray-500">
                        {episode.videoDurationPretty}
                    </span>
                </div>
            </div>
        </Link>
    )
}

const Toc = props => {
    const { collapsible = false } = props
    const [collapsed, setCollapsed] = useLocalStorage('collapsed', collapsible)
    const thisRef = React.useRef()

    const { course, episodes, autoplay, setAutoplay, active } = props
    return (
        <div className="h-full bg-white rounded shadow-sm" style={{ minWidth: '24rem' }}>
            <div className="flex flex-col h-full">
                <div className="flex items-center shadow justify-between px-4 py-5">
                    {collapsible ? (
                        <button
                            aria-label="Expand Episode List"
                            onClick={e => {
                                setCollapsed(!collapsed)
                            }}
                            className="w-1/2 flex justify-between items-end font-medium bg-gray-100 text-sm text-gray-800 px-3 py-2 hover:bg-gray-100 active:bg-gray-100 focus:bg-gray-100 rounded">
                            {episodes.length} Episodes
                            <svg
                                className="w-5 h-5 ml-2"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor">
                                {collapsed ? (
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M19 9l-7 7-7-7"
                                    />
                                ) : (
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M5 15l7-7 7 7"
                                    />
                                )}
                            </svg>
                        </button>
                    ) : (
                        <h2 className="text-gray-600 text-sm font-semibold">
                            {episodes.length} Episodes
                        </h2>
                    )}
                    <div>
                        <span className="mr-2 text-sm">Autoplay</span>
                        <input
                            checked={autoplay}
                            onChange={e => setAutoplay(e.target.checked)}
                            type="checkbox"
                            className="form-checkbox p-2"
                        />
                    </div>
                </div>
                <div
                    className={`${
                        collapsed && collapsible ? 'hidden' : 'block'
                    } flex-1 overflow-y-auto`}>
                    <ul ref={thisRef} className="h-full">
                        {episodes.map((e, i) => (
                            <li className="" key={i}>
                                <TocItem
                            course={course}
                                    count={episodes.length}
                                    index={i + 1}
                                    course={course}
                                    episode={e}
                                    isActive={e.slug === active.slug}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="order-first sm:order-none sm:border-t px-4 py-3">
                    <span className="relative w-full z-0 inline-flex shadow-sm">
                        <Link
                            replace
                            disabled={!active.prev}
                            to={active.prev || '/'}
                            className={`${
                                !!active.prev
                                    ? 'text-gray-500 hover:text-gray-400'
                                    : 'pointer-events-none text-gray-300'
                            }  relative w-1/2 inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium  focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150 transform duration-100 scale-100 active:scale-95`}>
                            <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                <path
                                    fillRule="evenodd"
                                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            Prev Episode
                        </Link>
                        <Link
                            replace
                            disabled={!active.next}
                            to={active.next || '/'}
                            className={`${
                                !!active.next
                                    ? 'text-gray-500 hover:text-gray-400'
                                    : 'pointer-events-none text-gray-300'
                            } relative w-1/2 ml-auto inline-flex justify-end items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150 transform duration-100 scale-100 active:scale-95`}>
                            Next Episode
                            <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                <path
                                    fillRule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </Link>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Toc
