import { Link, navigate } from 'gatsby'
import React from 'react'
import { useSession } from '../util/context.js'
import { useSiteMetadata } from '../hooks/site-metadata.js'
import ProfileDropdown from '../components/profile-dropdown.js'
import { FirebaseContext } from 'gatsby-plugin-firebase'
import { usePaidEpisode } from '../hooks/paid-episode.js'

export default props => {
    const { title } = useSiteMetadata()
    const { user } = useSession()
    const { course, episode } = props

    const [navOpen, setNavOpen] = React.useState(false)
    const [episodeData, loading] = usePaidEpisode(course, episode)

    const fb = React.useContext(FirebaseContext)
    const handleLogout = e => {
        if (!fb) return
        navigate('/') //Navigate before so other App page doesn't redirect us to /login again
        fb.auth().signOut()
    }
    const fixedClass = props.dontFixHeader ? '' : 'fixed top-0 z-50'

    return (
        <header
            className={`${fixedClass} z-50 w-full h-20 py-2 bg-white shadow md:flex items-center justify-between`}>
            <nav className="w-full">
                <div className="xl:mx-12 px-4 md:px-6 lg:px-8">
                    <div className="flex items-center justify-between h-16">
                        <div className="flex-1 flex items-center">
                            <div className="flex-1 flex items-center">
                                <Link
                                    to="/"
                                    className="text-gray-900 block flex justify-center sm:w-10 sm:h-10 sm:bg-teal-100 sm:rounded-full items-center border-2 border-transparent hover:border-teal-600 flex-shrink-0  transform duration-100 scale-100 hover:scale-110 active:scale-95">
                                    <svg
                                        className="mr-1 sm:mr-0 w-5 h-5"
                                        width="20"
                                        viewBox="0 0 24 22"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M0.061512 0.740892L11.5615 21.7596C11.7373 22.0801 12.2627 22.0801 12.4385 21.7596L23.9385 0.740892C24.0234 0.58597 24.0205 0.397326 23.9297 0.245335C23.8398 0.0933437 23.6767 0 23.5 0L0.499989 0C0.323231 0 0.160145 0.0933437 0.0703011 0.245335C-0.0205192 0.397326 -0.0234489 0.58597 0.061512 0.740892ZM16.9062 10.5L7.09374 10.5L12 1.54102L16.9062 10.5Z"
                                            fill="currentColor"
                                        />
                                    </svg>
                                    <span className="sm:hidden font-medium">{title}</span>
                                </Link>
                                <div className="hidden md:block ml-2 my-auto">
                                    <div className="ml-1 flex justify-start items-end">
                                        <Link
                                            to="/courses"
                                            className="pl-2 pr-1 py-2 rounded-md text-sm flex-center font-medium text-gray-700 hover:bg-gray-100 focus:shadow-outline focus:outline-none hover:underline">
                                            All Courses
                                            <svg
                                                className="ml-2 h-5 w-5 text-gray-400"
                                                fill="currentColor"
                                                viewBox="0 0 20 20">
                                                <path
                                                    fillRule="evenodd"
                                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </Link>
                                        <Link
                                            to={`/courses/${course.id}`}
                                            className="px-1 py-2 rounded-md text-sm flex-center font-semibold text-gray-800 focus:shadow-outline focus:outline-none hover:bg-gray-100 hover:underline ">
                                            {course.title}
                                        </Link>
                                    </div>
                                    <div className="ml-3 flex items-baseline">
                                        <h2 className="text-xl font-bold leading-5">
                                            Ep. {episode.title}{' '}
                                            {episode.videoDurationPretty && (
                                                <span className="px-1 text-sm text-gray-600 font-medium">
                                                    ({episode.videoDurationPretty})
                                                </span>
                                            )}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            {!loading && !course.isFree && !episodeData && (
                                <Link
                                    to={course.url}
                                    className={`hidden md:block group relative flex-center max-w-md px-4 py-2 font-medium text-gray-200 rounded shadow bg-gray-800 focus:bg-gray-900 hover:bg-gray-700
focus:outline-none focus:shadow-outline sm:text-sm sm:leading-5 transform duration-100 scale-100 active:scale-95`}>
                                    <span className="absolute left-0 inset-y pl-3">
                                        <svg
                                            className="h-5 w-5"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path
                                                className="group-hover:opacity-100 opacity-0"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"
                                            />

                                            <path
                                                className="group-hover:opacity-0 opacity-100"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                                            />
                                        </svg>
                                    </span>
                                    <span className="pl-5">Unlock Course</span>
                                </Link>
                            )}
                        </div>
                        <div className="hidden md:block">
                            <div className="ml-4 flex items-center md:ml-6">
                                {user ? (
                                    <ProfileDropdown user={user} />
                                ) : (
                                    <Link
                                        to="/login"
                                        className="inline-block sm:inline-block hover:border-gray-400 leading-5 text-sm border rounded-md px-6 py-2 bg-white border-gray-200 transform duration-100 scale-100 active:scale-95">
                                        Login
                                    </Link>
                                )}
                            </div>
                        </div>
                        <div className="-mr-2 flex md:hidden">
                            <button
                                aria-label="Toggle Nav"
                                onClick={() => setNavOpen(!navOpen)}
                                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:shadow-outline focus:outline-none focus:bg-gray-300 focus:text-gray-600">
                                <svg
                                    className="h-6 w-6"
                                    stroke="currentColor"
                                    fill="none"
                                    viewBox="0 0 24 24">
                                    <path
                                        className={`${navOpen ? 'hidden' : 'inline-flex'}`}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                    <path
                                        className={`${navOpen ? 'inline-flex' : 'hidden'}`}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                {navOpen && (
                    <div className="md:hidden bg-white">
                        <div className="px-2 pt-2 pb-3 sm:px-3">
                            <Link
                                to="/courses"
                                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">
                                Browse Courses
                            </Link>
                        </div>
                        <div className="pt-4 pb-3 border-t border-gray-300">
                            {user ? (
                                <div>
                                    <div className="flex items-center px-5">
                                        <div className="flex-shrink-0">
                                            {user.photoURL ? (
                                                <img
                                                    className="h-10 w-10 rounded-full"
                                                    src={user.photoURL}
                                                    alt=""
                                                />
                                            ) : (
                                                <svg
                                                    className="h-6 w-6"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor">
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                                    />
                                                </svg>
                                            )}
                                        </div>
                                        <div className="ml-3">
                                            <div className="text-base font-medium leading-none text-white">
                                                {user.name}
                                            </div>
                                            <div className="mt-1 text-sm font-medium leading-none text-gray-400">
                                                {user.email}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3 px-2">
                                        <Link
                                            to="/app/my-courses"
                                            className="block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-white hover:bg-gray-700 focus:shadow-outline focus:outline-none focus:text-white focus:bg-gray-700">
                                            My Courses
                                        </Link>
                                        <Link
                                            to="/support"
                                            className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-white hover:bg-gray-700 focus:shadow-outline focus:outline-none focus:text-white focus:bg-gray-700">
                                            Support
                                        </Link>
                                        <button
                                            onClick={handleLogout}
                                            className="mt-1 block w-full px-3 py-2 rounded-md text-left text-base font-medium text-gray-600 hover:text-white hover:bg-gray-700 focus:shadow-outline focus:outline-none focus:text-white focus:bg-gray-700">
                                            Logout
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div className="mt-1 px-2">
                                    <Link
                                        to="/login"
                                        className="mt-1 block px-4 py-2 rounded-md text-base font-medium text-gray-700 hover:text-white hover:bg-gray-700 focus:shadow-outline focus:outline-none focus:text-white focus:bg-gray-700">
                                        <span className="">Log In</span>
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </nav>
            <div className="md:hidden bg-gray-50 pl-4 pt-2 pb-2">
                <Link
                    to={`/courses/${course.id}`}
                    className="block leading-5 md:text-sm text-gray-600 flex-center font-semibold md:font-medium hover:underline hover:text-gray-800">
                    {course.title}
                </Link>
                <h2 className="block py-1 text-xl md:text-lg xl:text-lg">{episode.title}</h2>
            </div>
        </header>
    )
}
